import React from 'react';

import { A, ColorWay, H4, Icon, P } from '@driftt/tide-core';

function ErrorState() {
  return (
    <div className="video-library__empty-state">
      <ColorWay type="3">
        <Icon name="dizzybot-error" />
      </ColorWay>
      <H4 className="video-library__empty-state-header">Something went wrong.</H4>
      <P className="video-library__empty-state-copy" type="subtle">
        If this problem persists, chat with us or visit{' '}
        <A href="https://help.drift.com">help.drift.com</A>
      </P>
    </div>
  );
}

export default ErrorState;
