import {
  DECREMENT_PAGE,
  INCREMENT_PAGE,
  RESET_PAGE,
  SET_END_DATE,
  SET_PAGE_SIZE,
  SET_SEARCH_STRING,
  SET_SORT_DIRECTION,
  SET_SORT_KEY,
  SET_SOURCE_TYPE,
  SET_START_DATE,
  SET_TEAM,
  SET_USER_FILTER,
} from 'utils/actions';

export default function searchParametersReducer(state, action) {
  switch (action.type) {
    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.searchString,
        pageIndex: 0,
      };
    }
    case INCREMENT_PAGE: {
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    }
    case DECREMENT_PAGE: {
      return {
        ...state,
        pageIndex: state.pageIndex - 1,
      };
    }
    case RESET_PAGE: {
      return {
        ...state,
        pageIndex: 0,
      };
    }
    case SET_START_DATE: {
      let pageIndex = state.pageIndex;

      if (!state.startDate || action.startDate.isAfter(state.startDate)) {
        pageIndex = 0;
      }

      return {
        ...state,
        startDate: action.startDate,
        pageIndex,
      };
    }
    case SET_END_DATE: {
      let pageIndex = state.pageIndex;

      if (!state.endDate || action.endDate.isBefore(state.endDate)) {
        pageIndex = 0;
      }

      return {
        ...state,
        endDate: action.endDate,
        pageIndex,
      };
    }
    case SET_SORT_KEY: {
      return {
        ...state,
        sortKey: action.sortKey,
      };
    }
    case SET_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.sortDirection,
      };
    }
    case SET_PAGE_SIZE: {
      const scale = state.pageSize / action.pageSize;

      return {
        ...state,
        pageSize: action.pageSize,
        pageIndex: Math.floor(state.pageIndex * scale),
      };
    }
    case SET_USER_FILTER: {
      return {
        ...state,
        userFilter: action.userFilter,
      };
    }
    case SET_SOURCE_TYPE: {
      return {
        ...state,
        sourceType: action.sourceType,
        pageIndex: 0,
      };
    }
    case SET_TEAM: {
      return {
        ...state,
        team: action.team,
        searchString: null,
        pageIndex: 0,
        startDate: null,
        endDate: null,
        sourceType: null,
        sortKey: 'datetime_created',
        userFilter: 'all',
      };
    }
    default:
      throw new Error(`unknown action type "${action.type}"`);
  }
}
