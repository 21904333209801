import Adrianne1 from 'assets/idea-generator-photos/adrianne-1.png'
import Adrianne2 from 'assets/idea-generator-photos/adrianne-2.png'
import Alexa1 from 'assets/idea-generator-photos/alexa-1.png'
import Alexa2 from 'assets/idea-generator-photos/alexa-2.png'
import Conley1 from 'assets/idea-generator-photos/conley-1.png'
import Conley2 from 'assets/idea-generator-photos/conley-2.png'
import Dan1 from 'assets/idea-generator-photos/dan-1.png'
import Dan2 from 'assets/idea-generator-photos/dan-2.png'
import Kelvin1 from 'assets/idea-generator-photos/kelvin-1.png'
import Kelvin2 from 'assets/idea-generator-photos/kelvin-2.png'

export const videoIdeas = [
  {
    title: 'Invite a prospect to a virtual coffee.',
    description: 'Record a quick video offering your prospect a gift card to a local coffee shop in exchange for booking a meeting with you. This way you can continue the conversation over a virtual coffee.',
    employeeName: 'Adrianne O.',
    employeeInfo: 'Sales Rep at Drift',
    employeeImage1: Adrianne1,
    employeeImage2: Adrianne2
  },
  {
    title: 'Congratulate a prospect on recent news.',
    description: 'Find something about your prospect in the news – that could be organizational changes, funding, or even a great blog post they just published. Say congratulations and make the video all about them.',
    employeeName: 'Dan K.',
    employeeInfo: 'Sales Rep at Drift',
    employeeImage1: Dan1,
    employeeImage2: Dan2
  },
  {
    title: 'Follow up on your last few touches.',
    description: 'This one is simple. Just turn on your webcam, put on a big smile, and give a quick explanation of your recent attempts to get in touch with them. Make sure to add a prescriptive call to action.',
    employeeName: 'Conley E.',
    employeeInfo: 'Sales Rep at Drift',
    employeeImage1: Conley1,
    employeeImage2: Conley2
  },
  {
    title: 'Introduce yourself.',
    description: 'Help your prospects get to know you by sharing a little bit about what makes you you! It doesn’t have to be completely work related either: strike up a conversation by talking about what you like to do on the weekends or after work too.',
    employeeName: 'Kelvin S.',
    employeeInfo: 'Sales Rep at Drift',
    employeeImage1: Kelvin1,
    employeeImage2: Kelvin2
  },
  {
    title: 'Send a friendly meeting reminder.',
    description: 'Have a meeting coming up? Show your face and share your excitement to get on that call! Prospects are less likely to miss a meeting that you’ve personally helped them get excited for.',
    employeeName: 'Alexa N.',
    employeeInfo: 'Drift Video Product Manager',
    employeeImage1: Alexa1,
    employeeImage2: Alexa2
  }
]
