import React from 'react';

import PropTypes from 'prop-types';

import { Select } from '@driftt/tide-core';

import { SET_USER_FILTER } from 'utils/actions';

import './UserFilter.css';

const UserFilter = ({ users, userFilter, dispatch }) => {
  const sortedUserOptions = users
    .map((user) => ({
      label: [user.firstName, user.lastName].filter(Boolean).join(' ') || user.email || 'Teammate',
      value: user.id,
    }))
    .sort((u1, u2) => u1.label.localeCompare(u2.label));

  const options = [
    {
      label: 'Everyone',
      value: 'all',
    },
    ...sortedUserOptions,
  ];

  const selectedUser = options.find((user) => user.value === userFilter);

  return (
    <Select
      className="video-library-user-filter"
      size="small"
      placeholder={`Created by ${selectedUser.label}`}
      clearable={false}
      onChange={(element) => {
        dispatch({ type: SET_USER_FILTER, userFilter: element.value });
      }}
      options={options}
    />
  );
};

UserFilter.propTypes = {
  users: PropTypes.array.isRequired,
  userFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func.isRequired,
};

export default UserFilter;
