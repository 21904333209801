import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, DefaultContainer, Div, H1, H2, H3, H5, Icon,P, Span } from '@driftt/tide-core'

import { generateStaticUrls, shuffle } from 'utils'
import { segmentTrack } from 'utils/metrics'

import { videoIdeas } from './constants'

import './styles.css'

const ideas = shuffle(videoIdeas)

const VideoIdeaGeneratorApp = () => {
  const [currentIdea, setCurrentIdea] = useState(0)
  const [hasPickedIdea, setHasPickedIdea] = useState(false)
  const ideaData = ideas[currentIdea]
  const history = useHistory()

  const getCurrentIdeaTitle = () => ideas[currentIdea].title

  const nextIdea = () => {
    segmentTrack('Video Idea Generator -- Idea Action', {action: 'skipped', idea: getCurrentIdeaTitle()})
    setCurrentIdea((currentIdea + 1) % ideas.length)
  }

  const toggleHasPickedIdea = () => {
    if (hasPickedIdea) {
      segmentTrack('Video Idea Generator -- Idea Action', {action: 'unselected', idea: getCurrentIdeaTitle()})
    } else {
      segmentTrack('Video Idea Generator -- Idea Action', {action: 'selected', idea: getCurrentIdeaTitle()})
    }

    setHasPickedIdea(!hasPickedIdea)
  }

  const onSkipClick = () => {
    // add cookie, expires 1yr out
    document.cookie = 'has_skipped_idea_generator=True; expires=' + new Date(new Date().setFullYear(new Date().getFullYear() + 1)) + '; path=/'
    
    segmentTrack('Video Idea Generator Skipped')
    history.push('/')
  }
  
  return (
    <DefaultContainer className="video-idea-generator" style={{ backgroundImage: "url('/videos/assets/drift/graffiti-bg-large.png')"}}>
      <Div className="video-idea-main-content" id="video-idea-main-content">
        <H5 className="video-idea-subheader">Here&rsquo;s an idea for your first video:</H5>
        <H1 className="video-idea-header">{ideaData.title}</H1>
        {!hasPickedIdea ? (
          <>
            <P className="video-idea-body">{ideaData.description}</P>
            <Div className="video-idea-cta-container">
              <Button className="video-idea-cta" onClick={toggleHasPickedIdea}>Ready to record!</Button>
              <Button className="video-idea-refresh-container" onClick={nextIdea} type="utility">
                <Icon name="refresh-2" />
                <Span>New idea</Span>
              </Button>
            </Div>
            <Button className="video-idea-skip" onClick={onSkipClick} type="utility">
              I&rsquo;ll get started on my own
              <Icon name="arrow-1-right" />
            </Button>
          </>
        ) : (
          <Button className="video-idea-skip invert-margin" onClick={toggleHasPickedIdea} type="utility">
            <Icon name="arrow-1-left" />
            Wait, I want a different idea!
          </Button>
        )}
      </Div>
      <Div className="video-idea-assets-container" id="video-idea-assets-container">
        <Div className="employee-info-container">
          <H5 className="video-idea-subheader">This idea came from</H5>
          <H3>{ideaData.employeeName}</H3>
          <Span>{ideaData.employeeInfo}</Span>
        </Div>
        {!hasPickedIdea ? (
          <img className="employee-image" src={ideaData.employeeImage1} alt="Drift employee headshot" />
        ) : (
          <img className="employee-image" src={ideaData.employeeImage2} alt="Drift employee headshot" />
        )}
      </Div>
      {hasPickedIdea && (
        <Div className="video-idea-arrow-container" id="video-idea-arrow-container">
            <Icon className="video-idea-arrow-logo" name="drift-logo-round"/>
            <Div className="video-idea-arrow-copy">
                <Span>Click the Drift Video icon</Span>
                <Span>to start recording!</Span>
            </Div>
            <img
                className="video-idea-arrow"
                src={generateStaticUrls('/images/drift/gradient-arrow-up-right.svg')}
                alt="arrow"
            />
        </Div>
      )}
      <Div className="video-idea-refresher-container" id="video-idea-refresher-content">
        <H5 className="video-idea-subheader">Need a refresher?</H5>
        <H2>{ideaData.description}</H2>
      </Div>
    </DefaultContainer>
  )
}

export default VideoIdeaGeneratorApp
