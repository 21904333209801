import React, { useContext, useEffect,useState } from 'react';
import MomentPropTypes from 'react-moment-proptypes';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Button, ColorWay, H4, Icon, P } from '@driftt/tide-core';

import { UserContext } from 'context/UserContext';
import { NEW_EXTENSION_URL, SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING,SOURCE_TYPE_VIDEO } from 'utils/constants';
import { checkForNewExtension } from 'utils/extensionUtils';
import { segmentTrack } from 'utils/metrics';

import './EmptyState.css';

const DEFAULT_EMPTY_STATE_MESSAGE = 'Refine your search terms and try searching again.';

const getSourceTypeString = (sourceType) => {
  switch (sourceType) {
    case SOURCE_TYPE_VIDEO:
      return 'video';
    case SOURCE_TYPE_GIF:
      return 'GIF';
    case SOURCE_TYPE_MEETING_RECORDING:
      return 'meeting recording';
    default:
      return 'recording';
  }
};

function EmptyState({
  searchString,
  startDate,
  endDate,
  sourceType,
  isTeamResult,
  isFolder,
  isEmptyFolder,
  userFilter,
}) {
  const history = useHistory();
  const { createUrlPath } = useContext(UserContext);
  const [hasExtension, setHasExtension] = useState(false);

  useEffect(() => {
    checkForNewExtension().then((result) => {
      setHasExtension(result);
    });
  }, []);

  const hasDateFilterApplied = !!startDate || !!endDate;
  const hasSomeFilterApplied = Boolean(hasDateFilterApplied || searchString || userFilter);
  const isMeetingRecordingView = sourceType === SOURCE_TYPE_MEETING_RECORDING;

  if (isFolder && !isEmptyFolder) {
    return null;
  }

  if (isEmptyFolder && !hasSomeFilterApplied) {
    return (
      <div className="video-library__empty-state">
        <ColorWay type="3">
          <Icon name="cw-empty-search" />
        </ColorWay>
        <H4 className="video-library__empty-state-header">Looks like this folder is empty.</H4>
        <P className="video-library__empty-state-copy" type="subtle">
          Drag and drop to add videos here.
        </P>
      </div>
    );
  }

  // Install state for zoom integration
  if (isMeetingRecordingView && !window.hasEnabledZoomIntegration) {
    return (
      <div className="video-library__empty-state">
        <ColorWay type="1">
          <Icon name="video-camera" />
        </ColorWay>
        <H4 className="video-library__empty-state-header">
          You haven&rsquo;t set up the Zoom integration yet!
        </H4>
        <P className="video-library__empty-state-copy" type="subtle">
          Enable the Zoom integration in your settings to start syncing all of your meeting
          recordings.
        </P>
        <Button
          className="video-library__empty-state-button"
          type="primary"
          href={createUrlPath('/settings/integrations')}
          onClick={() =>
            segmentTrack('Empty Library CTA Clicked', {
              libraryState: 'meeting recordings',
            })
          }
        >
          Go to my settings
        </Button>
      </div>
    );
  }

  // Empty search state
  if (hasSomeFilterApplied || isTeamResult || !!sourceType) {
    let headerMessage = '';
    let bodyMessage = DEFAULT_EMPTY_STATE_MESSAGE;
    const sourceTypeString = getSourceTypeString(sourceType);

    // Build the header message
    if (isTeamResult && !hasSomeFilterApplied) {
      headerMessage = `Looks like your team hasn't made any ${sourceTypeString}s
                 visible to the team library yet.`;
    } else if (isTeamResult && hasDateFilterApplied) {
      headerMessage = `Looks like this date range doesn't include any team ${sourceTypeString}s.`;
    } else {
      headerMessage += 'We couldn’t find any ';

      if (isTeamResult) {
        headerMessage += 'team ';
      }

      headerMessage += sourceTypeString + 's';

      if (searchString) {
        headerMessage += ` with “${searchString}”`;
      }

      if (startDate && endDate) {
        headerMessage += ` between ${startDate.format('LL')} and ${endDate.format('LL')}`;
      } else if (startDate) {
        headerMessage += ` after ${startDate.format('LL')}`;
      } else if (endDate) {
        headerMessage += ` before ${endDate.format('LL')}`;
      }

      // Maybe update the body message
      if (isMeetingRecordingView) {
        bodyMessage =
          "Record your next Zoom meeting and it will sync into this library. Make sure 'cloud recordings' are turned on in your Zoom settings!";
      }
    }

    return (
      <div className="video-library__empty-state">
        <ColorWay type="1">
          <Icon name="cw-empty-search" />
        </ColorWay>
        <H4 className="video-library__empty-state-header">{headerMessage}</H4>
        <P className="video-library__empty-state-copy" type="subtle">
          {bodyMessage}
        </P>
        {isTeamResult && (
          <Button
            className="video-library__empty-state-button"
            type="tertiary"
            onClick={() => history.push('/video')}
          >
            Go to my recordings
          </Button>
        )}
      </div>
    );
  }

  // Empty library state
  return (
    <div className="video-library__empty-state">
      <ColorWay type="1">
        <Icon name="cw-video" />
      </ColorWay>
      <H4 className="video-library__empty-state-header">Get started with Drift Video</H4>
      <P className="video-library__empty-state-copy" type="subtle">
        Record your screen or face, then share with prospects to personalize your outreach and boost
        engagement.
      </P>
      {hasExtension ? (
        <Button
          onClick={() => history.push('/video/how-to/')}
          className="video-library__empty-state-button"
          type="tertiary"
        >
          See video examples
        </Button>
      ) : (
        <Button
          className="video-library__empty-state-button"
          type="primary"
          href={NEW_EXTENSION_URL}
          target="_blank"
        >
          Get the Chrome extension
        </Button>
      )}
    </div>
  );
}

EmptyState.propTypes = {
  searchString: PropTypes.string,
  startDate: MomentPropTypes.momentObj,
  endDate: MomentPropTypes.momentObj,
  sourceType: PropTypes.number,
  isTeamResult: PropTypes.bool.isRequired,
  isFolder: PropTypes.bool.isRequired,
  isEmptyFolder: PropTypes.bool.isRequired,
  userFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyState;
