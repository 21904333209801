import React from 'react';

import PropTypes from 'prop-types';

import { SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING,SOURCE_TYPE_VIDEO } from 'utils/constants';

function ResultCount({ count, sourceType }) {
  const singular = {
    null: 'recording',
    [SOURCE_TYPE_VIDEO]: 'video',
    [SOURCE_TYPE_GIF]: 'GIF',
    [SOURCE_TYPE_MEETING_RECORDING]: 'Zoom recording',
  }[sourceType];

  const plural = {
    null: 'recordings',
    [SOURCE_TYPE_VIDEO]: 'videos',
    [SOURCE_TYPE_GIF]: 'GIFs',
    [SOURCE_TYPE_MEETING_RECORDING]: 'Zoom recordings',
  }[sourceType];

  return (
    <span className="video-library__video-count">
      {count} {count === 1 ? singular : plural}
    </span>
  );
}

ResultCount.propTypes = {
  count: PropTypes.number.isRequired,
  sourceType: PropTypes.number,
};

export default ResultCount;
