import React, { useState } from 'react';
import MomentPropTypes from 'react-moment-proptypes';

import moment from 'moment';
import PropTypes from 'prop-types';

import { DriftDatePicker } from '@driftt/react-dates';

import './styles.css';

function DatePicker({ startDate, endDate, onStartDateChange, onEndDateChange }) {
  const [focusedInput, setFocusedInput] = useState(null);

  const onDateChange = ({ startDate, endDate }) => {
    if (startDate) {
      onStartDateChange(startDate);
    }

    if (endDate) {
      onEndDateChange(endDate);
    }
  };

  const isOutsideRange = (day) => moment(day).isAfter(moment().endOf('day'));

  return (
    <DriftDatePicker
      startDate={startDate}
      endDate={endDate}
      onDatesChange={onDateChange}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      isOutsideRange={isOutsideRange}
      showQuickSelects
      anchorDirection="left"
    />
  );
}

DatePicker.propTypes = {
  startDate: MomentPropTypes.momentObj,
  endDate: MomentPropTypes.momentObj,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
};

export default DatePicker;
