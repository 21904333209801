import React from 'react';

import PropTypes from 'prop-types';

import { Button, Div, Input, Select } from '@driftt/tide-core';

import { SET_END_DATE, SET_SOURCE_TYPE,SET_START_DATE } from 'utils/actions';
import { SOURCE_TYPE_GIF, VIDEO_SOURCE_TYPES } from 'utils/constants';

import DatePicker from './DatePicker';
import ResultCount from './ResultCount';
import UserFilter from './UserFilter';

import './styles.css';

const VideoLibraryFilters = ({
  sourceType,
  team,
  searchString,
  teamLibrary,
  users,
  userFilter,
  dispatch,
  startDate,
  endDate,
  count,
  folder,
  videosLoading,
  exportCsv,
  handleSearchInputChange,
}) => {
  return (
    <Div className="video-library-filter-controls">
      <Input
        key={`${sourceType}${team}`}
        className="video-library-search"
        size="small"
        type="search"
        placeholder="Search..."
        defaultValue={searchString}
        onChange={(e) => handleSearchInputChange(e.target.value)}
      />
      {teamLibrary && <UserFilter users={users} userFilter={userFilter} dispatch={dispatch} />}
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={(startDate) => dispatch({ type: SET_START_DATE, startDate })}
        onEndDateChange={(endDate) => dispatch({ type: SET_END_DATE, endDate })}
      />
      <Select
        className="source-type-select"
        placeholder={'All recordings'}
        value={sourceType}
        onChange={({ value }) => dispatch({ type: SET_SOURCE_TYPE, sourceType: value })}
        clearable={false}
        searchable={false}
        options={VIDEO_SOURCE_TYPES}
      />
      {count && !videosLoading ? <ResultCount count={count} sourceType={sourceType} /> : null}
      {!teamLibrary && sourceType !== SOURCE_TYPE_GIF && !folder?.readonly && (
        <Button className="video-library__export-csv-button" type="tertiary" onClick={exportCsv}>
          Export analytics CSV
        </Button>
      )}
    </Div>
  );
};

VideoLibraryFilters.propTypes = {
  sourceType: PropTypes.number,
  team: PropTypes.string,
  searchString: PropTypes.string,
  teamLibrary: PropTypes.bool,
  users: PropTypes.array,
  userFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  count: PropTypes.number,
  folder: PropTypes.object,
  videosLoading: PropTypes.bool,
  exportCsv: PropTypes.func,
  handleSearchInputChange: PropTypes.func,
}

export default VideoLibraryFilters;
